<!--
 * @Description: AKJERRT
 * @Date: 2022-07-11 17:35:05
 * @LastEditTime: 2022-07-11 18:02:57
 * @FilePath: \linkgap-front\src\views\account_statement\components\handler\confirm.vue
-->
<template>
  <a-modal title="确认账单" width="50%" v-model="visible" :footer="null" :maskClosable="false">
    <a-form :form="res_data">
      <a-form-item label="账单核对" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
        <a-radio-group v-model="res_data.value">
          <a-radio :value="1"> 相符 </a-radio>
          <a-radio :value="2"> 不相符 </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="对账说明" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
        <a-textarea :rows="6" placeholder="对账说明" v-model="res_data.DZSM"></a-textarea>
      </a-form-item>
      <a-form-item label="上传附件" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
        <QiniuUpload type="image" :disabled="handle == 'check'" :value.sync="res_data.fileUrl"></QiniuUpload>
      </a-form-item>
    </a-form>
    <a-row type="flex" justify="end">
      <a-col>
        <!-- <a-button @click="toSubmit">打印</a-button>
        <a-button @click="toSubmit">下载</a-button>
        <a-button @click="toSubmit">暂存</a-button> -->
        <a-button @click="toSubmit">提交</a-button>
        <a-button @click="toSubmit">取消</a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
export default {
  name: 'confirm',
  data() {
    return {
      handle: '',
      res_data: {
        DZSM: '',
        fileUrl: '',
        value: 1,
      },
      visible: false,
    }
  },
  methods: {
    init() {
      this.visible = true
    },
    toSubmit() {},
  },
}
</script>
